<template>
  <div class="merchantadd-container position-relative">
    <div class="text-center sale-font mb-25 font-weight">
      供货单号：{{ merchantList.supplier_order_no }}
    </div>
    <div>
      <el-form
        label-width="120px"
        :model="merchantList"
        ref="addForm"
        :inline="true"
        :hide-required-asterisk="true"
      >
        <div>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="供货商姓名" prop="fullname">
                <div v-if="merchantList.supplier">
                  {{ merchantList.supplier.fullname }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系电话" prop="mobile">
                <div v-if="merchantList.supplier">
                  {{ merchantList.supplier.mobile }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="操作员" prop="fullname">
                <span>{{ getSupplierBuyerName(merchantList.buyer) }}</span>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="档口位置" prop="carLicense">
                <div v-if="merchantList.supplier">
                  {{ merchantList.supplier.stall }}
                </div>
              </el-form-item>
            </el-col> -->
          </el-row>
          <div class="auto-table-flex mb-30">
            <el-table
              :data="goodsData"
              max-height="600"
              v-loading="loading"
              :border="true"
              :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
            >
              <el-table-column
                type="index"
                width="150"
                align="center"
                label="序号"
              >
              </el-table-column>
              <el-table-column prop="goodsName" align="center" label="主图">
                <template slot-scope="scope" v-if="scope.row.product">
                  <img
                    @click.stop="getGlass(scope.row.product.kv)"
                    class="kv pointer"
                    :src="scope.row.product.kv"
                    alt=""
                  />
                </template>
              </el-table-column>
              <el-table-column prop="title" align="center" label="名称">
                <template slot-scope="scope" v-if="scope.row.product">
                  <span
                    >{{ $empty.empty(scope.row.product.title) }} ({{
                      $empty.empty(scope.row.product.sku_code)
                    }})</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="grade" align="center" label="等级">
                <template slot-scope="scope" v-if="scope.row.product">
                  <span>{{ $empty.empty(scope.row.product.grade) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="gross_weight"
                align="center"
                label="毛重(斤)"
              >
                <template slot-scope="scope" v-if="scope.row.product">
                  <span>{{
                    $empty.empty(scope.row.product.gross_weight)
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="weight" align="center" label="净重(斤)">
                <template slot-scope="scope" v-if="scope.row.product">
                  <span>{{ $empty.empty(scope.row.product.weight) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="product_amount"
                align="center"
                label="金额(元)"
              >
                <template slot-scope="scope">
                  <span>{{
                    $empty.empty(tool.format_money(scope.row.product_amount))
                  }}</span>
                </template>
              </el-table-column>

              <!-- 会员改版需求 start -->
              <el-table-column
                prop="product"
                align="center"
                label="非会员价"
                min-width="100"
              >
                <template slot-scope="scope">
                  <span>{{
                    (scope.row.product &&
                      (scope.row.product.price / 100).toFixed(2)) ||
                    "-"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="membership_price"
                align="center"
                label="会员价"
                min-width="100"
              >
                <template slot-scope="scope">
                  <span>{{
                    (scope.row.product &&
                      (scope.row.product.membership_price / 100).toFixed(2)) ||
                    "-"
                  }}</span>
                </template>
              </el-table-column>
              <!-- 会员改版需求 end -->

              <el-table-column
                prop="product_number"
                align="center"
                label="数量"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.product">{{
                    $empty.empty(scope.row.product_number)
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>
    </div>
    <div class="fy1" style="position: absolute; bottom: 0; left: 0; right: 0">
      <el-button @click="$router.back()">返回</el-button>
    </div>
    <div v-show="showImage" class="shadow" @click="showImage = false"></div>
    <div v-show="showImage" class="bigImage">
      <img :src="bigImage" alt="" />
      <i class="el-icon el-icon-close bigClose" @click="showImage = false"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      merchantList: {},
      id: "",
      bigImage: "",
      showImage: false,
      goodsData: [
        //商品信息
      ],
      loading: false,
    };
  },
  computed: {
    /**
     * 获得业务员名称
     */
    getSupplierBuyerName() {
      return (data) => {
        const res = data?.fullname;
        return res || "-";
      };
    },
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      this.$api.general.supplierOrderDetail({ id: this.id }).then((res) => {
        this.merchantList = res.data;
        this.goodsData = res.data.supplier_order_detail;
      });
    },
    getGlass(src) {
      this.bigImage = src;
      this.showImage = true;
    },
    // 商品信息合计方法
    goodsCount(param) {
      const { columns, data } = param;
      console.log(data);
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计：" + this.merchantList.order_amt + "元";
          return;
        }
      });
      sums["1"] = "运费：" + this.merchantList.freight_amt + "元";
      sums["2"] = "金融手续费：" + this.merchantList.finance_amt + "元";
      return sums;
    },
  },
};
</script>

<style lang="less">
.merchantadd-container {
  .kv {
    width: 100px;
    height: 100px;
  }
  .sale-font {
    font-size: 15px;
  }
  padding: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  min-height: calc(100% - 20px);
  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  // .auto-table-flex .el-table .el-table__footer-wrapper .cell {
  //   color: #E65A34;
  // }
}
</style>
